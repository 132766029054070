@import 'style/style.module';
@import 'style/landingBreakpoints.scss';

.main {
  background-size: $fullHdWidth auto;
  flex: 0 0 auto;
  min-height: 100%;
  background: $dark;

  .products {
    padding-top: 110px;
    padding-bottom: 50px;
    position: relative;
    transition: padding 0.2s;

    @media only screen and (max-width: $sdWidth) {
      padding-bottom: 0;
      padding-top: 50px;
    }

    @media only screen and (max-width: $sdWidth) and (max-height: 540px) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 15px;
      font-size: 64px;
      font-weight: 900;
      line-height: 1.1;
      color: $white;
      @media only screen and (max-width: $sdWidth) {
        font-size: 36px;
        margin-bottom: 10px;
      }
    }
    .subTitle {
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $grey;
      @media only screen and (max-width: $sdWidth) {
        font-size: 18px;
        line-height: 1.4;
      }
      @media only screen and (max-width: $mobileWidth) {
        max-width: 350px;
        text-align: center;
      }
    }
    .grid {
      margin-top: 80px;
      gap: 80px 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      width: 100%;
      @media only screen and (max-width: $sdWidth) {
        margin: 60px 0 0 0;
        gap: 60px 20px;
        display: flex;
        flex-wrap: wrap;
      }

      .el {
        width: 100%;
        max-width: 412px;
        background-size: 412px auto !important;
        border-radius: 30px;
        padding: 66px 30px 36px 30px;
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $sdWidth) {
          flex: 0 0 auto;
          width: 100%;
          max-width: 350px;
          background-color: $blocks;
          background-repeat: no-repeat;
          background-position: 50% 0;
          background-size: 350px auto !important;
          border-radius: 20px;
          padding: 50px 20px 20px 20px;
          display: flex;
          flex-direction: column;
        }

        .type {
          width: auto;
          position: absolute;
          top: 0;
          left: 50%;
          white-space: nowrap;
          transform: translate3d(-50%, -50%, 0);
          text-align: center;
          color: $white;
          padding: 2px;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          border-radius: 26px;
          text-transform: uppercase;
          letter-spacing: 0.06em;
          background: $dark;
          @media only screen and (max-width: $sdWidth) {
            border-radius: 22px;
            font-size: 12px;
            line-height: 15px;
          }
          .typeItem {
            padding: 0 21px;
            height: 50px;
            margin: 0;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.06em;
            line-height: 50px;
            border-radius: 25px;
            min-width: 170px;
            background: $dark;
            $border: 0.1px;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: -1;
              margin: -$border;
              border-radius: inherit;
              background: linear-gradient(90deg, #e571ff 0%, #ff8937 100%);
            }
            @media only screen and (max-width: $sdWidth) {
              padding: 0 22px;
              height: 40px;
              font-size: 12px;
              line-height: 40px;
              border-radius: 20px;
              min-width: 140px;
              background: $mdark;
              letter-spacing: 1px;

              &:before {
                background: $dark;
              }
            }
          }
        }
        .gridTitle {
          height: 518px;
          font-weight: 900;
          color: $white;
          font-size: 36px;
          line-height: 1.2;
          @media only screen and (max-width: $sdWidth) {
            height: 518px;
            font-size: 30px;
          }
          @media only screen and (max-width: $mobileWidth) {
            height: 447px;
            font-size: 30px;
          }
          .grad {
            background: $grad2;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            display: inline-block;
            vertical-align: top;
            @media only screen and (min-width: ($sdWidth + 1)) {
              margin: 0 -15px;
            }
          }
        }
        .text {
          font-size: 20px;
          margin: 0 auto;
          font-weight: bold;
          line-height: 1.15;
          color: $white;
          flex: 1 0 auto;
          @media only screen and (max-width: $sdWidth) {
            font-size: 18px;
            display: flex;
            align-items: flex-end;
          }
        }
        .text2ver {
          @extend .text;
        }
        .links {
          display: flex;
          justify-content: center;
          gap: 10px;
          a {
            display: flex;
            justify-content: center;
            flex: 1 1 auto;
            text-decoration: none;
          }
          margin: 30px 0 0 0;
          .transitionStyle {
            transition-property: opacity, background, color, fill;
            transition-duration: 0.2s;
            transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          .btnWhite {
            flex: 1;
            align-self: flex-start;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            text-align: center;
            height: 55px;
            border-radius: 15px;
            font: bold 16px/1.45 $font;
            cursor: pointer;
            max-width: 256px;
            @extend .transitionStyle;
            transform: scale(1);
            gap: 10px;
            opacity: 1;
            color: $dark;
            background: $white;
            position: relative;
            overflow: hidden;
            @media only screen and (max-width: $sdWidth) {
              max-width: 100%;
            }
            .btnPic {
              display: flex;
              align-items: center;
              justify-content: center;
              background: #f7f4f1;
              width: 30px;
              height: 30px;
              border-radius: 12px;
              @extend .transitionStyle;
              svg {
                fill: $windows;
                width: 12px;
                height: 12px;
                @extend .transitionStyle;
              }
            }

            .btnPicMacOS {
              @extend .btnPic;
              border-radius: 50%;
              @extend .transitionStyle;
              svg {
                width: 18px;
                height: 18px;
              }
            }
            span {
              position: relative;
              z-index: 9;
            }
            &:active {
              transform: scale(0.95);
            }
            &:before {
              width: 100%;
              height: 100%;
              content: '';
              background: $grad;
              position: absolute;
              z-index: 5;
              opacity: 0;
              @extend .transitionStyle;
            }
          }

          .btnGrad {
            @extend .btnWhite;
            &:hover:before {
              width: 100%;
              height: 100%;
              content: '';
              background: $grad;
              position: absolute;
              z-index: 5;
              opacity: 1;
            }
            &:hover {
              color: $white;
            }
          }

          .btnOs {
            z-index: 10;
            @extend .btnWhite;
            align-items: center;
            &:hover {
              background: $windows;
              opacity: 1;
              color: $white;
              .btnPic {
                background: rgba(247, 244, 241, 0.1);
                svg {
                  opacity: 0.95;
                  fill: $white;
                  @extend .transitionStyle;
                }
              }
              .btnPicMacOS {
                @extend .transitionStyle;
                background: rgba(9, 3, 18, 0.2);
              }
            }
          }
          .btnMacOs {
            @extend .btnOs;
            &:hover {
              background: $macos;
            }
          }
        }
      }
      .bg1 {
        background: url(/images/products/copytrading.jpg) no-repeat top center;
      }
      .bg2 {
        background: url(/images/products/platform.jpg) no-repeat top center;
      }
      .bg3 {
        background: url(/images/products/broker.jpg) no-repeat top center;
      }
      .bg4 {
        background: url(/images/products/exchange.jpg) no-repeat top center;
      }
    }
  }
}
