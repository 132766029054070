@import 'style/style.module';
@import 'style/landingBreakpoints.scss';

.main {
  flex: 0 0 auto;
  min-height: 900px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $hdWidth) and (max-height: $maximumBkgFullScreenHeight) {
    min-height: 100vh;
  }

  @media only screen and (max-height: 900px) {
    min-height: auto;
  }

  @media only screen and (max-width: $mobileWidth) {
    min-height: auto;
  }

  .topBlock {
    text-align: left;
    padding: 0;
    transition: padding 0.2s;

    @media only screen and (max-width: $hdWidth) {
      min-height: auto;
    }

    @media only screen and (max-width: $hdWidth) and (max-height: $maximumBkgFullScreenHeight) {
      min-height: 100vh;
    }

    @media only screen and (max-width: $mobileWidth) {
      text-align: center;
      padding-top: 350px;
      min-height: auto;
    }

    @media only screen and (max-width: 500px) {
      padding-top: 350px;
    }
  }

  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    padding-top: 148px;
    gap: 50px;
    min-height: auto;

    @media only screen and (max-height: $maximumBkgFullScreenHeight) {
      min-height: 100vh;
    }

    @media only screen and (min-height: ($maximumBkgFullScreenHeight + 1)) {
      min-height: $sdWidth + 1;
    }

    @media only screen and (max-width: $sdWidth) {
      min-height: 600px;
      padding-bottom: 20px;
      padding-top: 80px;
      gap: 40px;
    }

    @media only screen and (max-width: $mobileWidth) {
      flex: 0 0 auto;
      min-height: 400px;
      width: 100%;
      padding: 0 20px;
      margin: 0 auto;
      max-width: 390px;
      justify-content: inherit;
      gap: 0;
    }

    .content {
      flex: 0 0 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: auto 0;
      @media only screen and (max-width: $mobileWidth) {
        align-items: center;
        margin: 0;
      }
      .swiss {
        margin-bottom: 15px;
        color: $white;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        @media only screen and (max-width: $sdWidth) {
          margin-bottom: 5px;
        }
        svg {
          height: 25px;
          width: auto;
          margin-right: 10px;
        }
      }
      .title {
        margin-bottom: 15px;
        font-size: 90px;
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;
        color: $white;
        @media only screen and (max-width: $sdWidth) {
          font-size: 44px;
          line-height: 1.1;
          margin-bottom: 8px;
        }
        @media only screen and (max-width: $mobileWidth) {
          text-transform: none;
        }
        .grad {
          display: inline-block;
          vertical-align: top;
          background: $grad;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
      .subTitle {
        font-size: 20px;
        line-height: 1.4;
        max-width: 30em;
        font-weight: 600;
        color: $grey;
        mark {
          background: none;
          font-style: normal;
          color: #ffff19;
        }
        /**
         Для мобилок убъем br-ку
          */
        br {
          @media only screen and (max-width: $mobileWidth) {
            display: none;
          }
        }
        @media only screen and (max-width: $sdWidth) {
          font-size: 16px;
          line-height: 1.45;
        }
      }
      .btnGrad {
        margin: 30px 0 0 0;
        background: $grad;
        align-self: flex-start;
        min-width: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-align: center;
        height: 55px;
        border-radius: 15px;
        font: bold 16px/1.45 $font;
        cursor: pointer;
        padding: 0 24px;
        max-width: 100%;
        transition-property: opacity;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: scale(1);
        opacity: 1;
        &:active {
          transform: scale(0.95);
        }
        &:hover {
          background: $gradHover;
          opacity: 0.96;
        }
        @media only screen and (max-width: $mobileWidth) {
          align-self: center;
          width: 100%;
        }
      }
    }
    .bottom {
      display: flex;
      flex: 0 0 auto;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px 40px;
      font-weight: bold;
      font-size: 18px;
      line-height: 1.55;
      color: $white;
      @media only screen and (max-width: $sdWidth) {
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
        align-items: flex-end;
        display: flex;
      }
      @media only screen and (max-width: $mobileWidth) {
        flex-wrap: nowrap;
        justify-content: center;
        flex-direction: row;
        font-size: 14px;
        line-height: 22px;
        margin: 60px 0 0 0;
        gap: 20px;
      }
      .item {
        display: flex;
        font-size: 15px;
        @media only screen and (max-width: $mobileWidth) {
          align-items: center;
          flex-direction: column;
          max-width: 150px;
        }
        svg {
          margin-right: 15px;
          @media only screen and (max-width: $mobileWidth) {
            margin-right: 0;
            margin-bottom: 15px;
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
