@import 'style/style.module';
.main {
  background-size: 1440px auto;
  flex: 0 0 auto;
  min-height: 100%;
  .connect {
    padding-top: 150px;
    padding-bottom: 30px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      padding-top: 100px;
      padding-bottom: 0;
    }
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 1023px) {
      max-width: 1024px;
    }
    .title {
      font-size: 56px;
      line-height: 1.15;
      margin: 0 auto 40px auto;
      max-width: 15em;
      text-align: center;
      font-weight: 900;
      color: $white;
      @media only screen and (max-width: 1023px) {
        font-size: 36px;
        line-height: 1.1;
        max-width: 390px;
        margin: 0 auto 30px auto;
      }
      .grad {
        display: inline-block;
        vertical-align: top;
        background: $grad;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }
  .wrap {
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 1023px) {
      &:before {
        left: 0;
        background: linear-gradient(to right, $dark 0%, $dark0 100%);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 70px;
        z-index: 15;
        pointer-events: none;
      }
      &:after {
        right: 0;
        background: linear-gradient(to left, $dark 0%, $dark0 100%);
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 70px;
        z-index: 15;
        pointer-events: none;
      }
    }
    .line {
      height: 109px;
      flex: 0 0 auto;
      background-position: 0 0;
      background-size: auto 100%;
      background-repeat: repeat-x;
      transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      will-change: transform;
      position: relative;
      @media only screen and (max-width: 1023px) {
        height: 80px;
      }
    }
    .line1 {
      background-image: url(/images/connect/line1.svg);
      -webkit-animation: line1 30s linear infinite;
      animation: line1 30s linear infinite;
      position: relative;
      z-index: 10;
    }
    .line2en,
    .line2ru {
      position: relative;
      z-index: 5;
      margin-top: -22px;
      margin-left: -80px;
      opacity: 0.5;
      -webkit-animation: line2 30s linear infinite;
      animation: line2 30s linear infinite;
    }
    .line2en {
      background-image: url(/images/connect/line2en.svg);
    }
    .line2ru {
      background-image: url(/images/connect/line2ru.svg);
    }
  }
}

@-webkit-keyframes line1 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2250px 0;
  }
}
@keyframes line1 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2250px 0;
  }
}
@-webkit-keyframes line2 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2500px 0;
  }
}
@keyframes line2 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2500px 0;
  }
}
