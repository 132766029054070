@import 'style/style.module';
.main {
  background-size: 1440px auto;
  flex: 0 0 auto;
  min-height: 100%;
  .faq {
    padding-top: 150px;
    position: relative;
    text-align: center;
    display: block;
    @media only screen and (max-width: 1023px) {
      padding-top: 100px;
    }
  }
  .extraSpacing {
    margin: 20px 0 20px 0;
  }
  .extraBottomSpacing {
    margin-bottom: 50px;
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    gap: 40px;
    align-items: flex-start;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }
    @media only screen and (max-width: 767px) {
      max-width: 390px;
    }

    .title {
      flex: 0 0 auto;
      font-size: 56px;
      width: calc(33.333333% - 40px);
      margin: 0;
      text-align: left;
      max-width: 5em;
      font-weight: 900;
      line-height: 1.1;
      color: $white;
      @media only screen and (max-width: 1023px) {
        font-size: 36px;
      }
      @media only screen and (max-width: 767px) {
        text-align: center;
        max-width: 100%;
        width: 100%;
      }
    }
    .wrap {
      width: 66.666666%;
      max-width: 830px;
      @media only screen and (max-width: 1023px) {
        width: calc((100% - 20px) / 1.5);
      }
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      .el {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.45;
        @media only screen and (max-width: 1023px) {
          font-size: 16px;
        }
        &:not(:last-child) {
          margin-bottom: 25px;
          @media only screen and (max-width: 1023px) {
            margin-bottom: 38px;
          }
        }
        .elTitle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 24px;
          line-height: 1.3;
          font-weight: 600;
          min-height: 50px;
          margin: 0;
          position: relative;
          gap: 15px;
          cursor: pointer;
          color: $white;
          user-select: none;
          @media only screen and (max-width: 1023px) {
            font-size: 18px;
            line-height: 1.45;
            min-height: 52px;
            font-weight: 600;
          }
          @media only screen and (max-width: 767px) {
            text-align: left;
          }
          .arrow {
            flex: 0 0 auto;
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            background: $mdark;
            border-radius: 15px;
            position: relative;
            $border: 1px;
            padding: 20px;
            background-clip: padding-box;
            border: solid $border transparent;
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              border-radius: inherit;
              background: $grad;
              z-index: -1;
              margin: -$border;
              opacity: 0;
              transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              border-radius: inherit;
              background: url(/images/faq-arrow.svg) no-repeat 50% 50%;
              background-size: 21px auto;
              transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              transform: rotate(0deg);
            }
          }
          &:active .arrow {
            width: 47.5px;
            height: 47.5px;
          }
        }

        .in {
          width: 100%;
          height: 0;
          overflow: hidden;
          color: $grey;
          .ind {
            text-align: left;
            padding: 25px 0 15px 0;
            max-width: 724px;
            ul li::before {
              content: '●';
              font-size: 5px;
              padding-top: 9px;
            }
            @media only screen and (max-width: 1023px) {
              padding: 15px 0 0 0;
            }

            .topOffset {
              margin-top: 15px;
            }

            mark {
              color: #fff;
              background: none;
              font-weight: 600;
            }

            .link,
            .linkSecondary {
              color: currentColor;
              text-decoration: underline;

              &:hover {
                color: #fff;
              }
            }

            .link {
              color: #fff;
            }
          }
        }

        &:hover {
          .elTitle {
            .arrow:before {
              opacity: 1;
            }
          }
        }
      }

      .openedEl {
        @extend .el;
        .elTitle {
          .arrow {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
        .in {
          height: 100%;
        }
      }
    }
  }
}

@keyframes dropdownOpen {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
    color: red;
  }
}
