@import 'style/style.module';
.main {
  background-size: 1440px auto;
  flex: 0 0 auto;
  min-height: 100%;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  .contact {
    padding-top: 142px;
    padding-bottom: 92px;
    margin-top: 180px;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    display: block;
    @media only screen and (max-width: 1023px) {
      margin: 100px 0 0 0;
      padding: 80px 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      pointer-events: none;
      background: url(/images/questions.png) no-repeat 0 50%;
      background-size: auto 100%;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      pointer-events: none;
      background: url(/images/questions.png) no-repeat 0 50%;
      background-size: auto 100%;
      transform: scale(-1, 1);
    }
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    .title {
      text-align: center;
      margin-bottom: 40px;
      font-size: 64px;
      font-weight: 900;
      line-height: 1.1;
      color: $white;
      @media only screen and (max-width: 1023px) {
        font-size: 36px;
      }
    }
    .btnGrad {
      align-self: center;
      width: auto;
      min-width: 320px;
      background: $grad;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-align: center;
      border-radius: 15px;
      font: bold 16px/1.45 $font;
      color: $white;
      cursor: pointer;
      padding: 0 24px;
      max-width: 100%;
      transition-property: opacity;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: scale(1);
      opacity: 1;
      &:active {
        transform: scale(0.95);
      }
      &:hover {
        background: $gradHover;
        opacity: 0.96;
      }
    }
  }
}
