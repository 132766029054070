@import 'style/style.module';
.main {
  background-size: 1440px auto;
  flex: 0 0 auto;
  min-height: 100%;

  .partners {
    padding-top: 150px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      padding-top: 100px;
    }
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 767px) {
      max-width: 390px;
    }
    .title {
      max-width: 997px;
      text-align: center;
      margin: 0 auto 20px auto;
      font-size: 64px;
      font-weight: 900;
      line-height: 1.1;
      color: $white;
      span {
        strong {
          font-weight: 900;
          color: $binance;
        }
      }

      @media only screen and (max-width: 1023px) {
        font-size: 36px;
      }
    }
    .subTitle {
      max-width: 845px;
      font-size: 24px;
      font-weight: 600;
      line-height: 1.4;
      color: $grey;
      strong {
        color: $binance;
      }
      @media only screen and (max-width: 1023px) {
        font-size: 20px;
      }
    }
    .grid {
      margin-top: 80px;
      gap: 30px;
      display: flex;

      justify-content: center;
      width: 100%;
      @media only screen and (max-width: 1023px) {
        flex-wrap: inherit;
        gap: 10px;
        margin-top: 40px;
      }
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
      .el {
        justify-content: space-between;
        width: calc((100% - 60px) / 3);
        max-width: 406px;
        font-size: 18px;
        line-height: 21px;
        padding: 50px 40px 40px 40px;
        flex: 0 0 auto;
        text-decoration: none;
        font-weight: 600;
        color: $grey;
        position: relative;
        border-radius: 30px;
        background: $blocks;
        transform: translate3d(0, 0, 0);
        transition-property: color, background, transform;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        text-align: left;
        display: flex;
        flex-direction: column;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        @media only screen and (max-width: 1023px) {
          width: calc((100% - 20px) / 3);
          max-width: 350px;
          font-size: 16px;
          line-height: 19px;
          padding: 40px 20px;
        }
        @media only screen and (max-width: 767px) {
          padding: 40px 30px;
          width: 100%;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          border-radius: inherit;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          z-index: 5;
          opacity: 1;
        }

        &:first-child:before {
          background: url(/images/partners/bg1.svg) no-repeat 0 50%;
          background-size: cover;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:nth-child(2):before {
          background: url(/images/partners/bg2.svg) no-repeat 0 50%;
          background-size: cover;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &:last-child:before {
          background: url(/images/partners/bg3.svg) no-repeat 0 50%;
          background-size: cover;
          transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        .top {
          .pic {
            margin: 0 0 25px 0;
            height: 55px;
            position: relative;
            z-index: 10;
            width: max-content;
            svg {
              border-radius: 16px;
            }
          }
          .label {
            font-size: 18px;
            line-height: 21px;
            font-weight: 600;
            text-align: left;
            color: $grey;
            position: relative;
            z-index: 10;
            @media only screen and (max-width: 1023px) {
              font-size: 16px;
              line-height: 19px;
            }
          }
          .text {
            font-size: 33px;
            line-height: 1.2;
            max-width: 320px;
            transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            color: $white;
            position: relative;
            z-index: 10;
            margin-top: 10px;
            font-weight: 900;
            @media only screen and (max-width: 1023px) {
              font-size: 30px;
            }
          }
        }
        .btn {
          justify-items: flex-end;
          margin-top: 114px;
          height: 65px;
          background: #13101e;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          text-decoration: none;
          border: 0;
          text-align: center;
          overflow: hidden;
          border-radius: 15px;
          font: bold 16px/1.45 $font;
          cursor: pointer;
          padding: 0 24px;
          flex: 0 0 auto;
          max-width: 100%;
          position: relative;
          transition-property: color, background, opacity;
          transition-duration: 0.2s;
          transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          transform: scale(1);
          z-index: 10;
          opacity: 0;
          @media only screen and (max-width: 1023px) {
            margin-top: 50px;
            opacity: 1;
          }

          &:active {
            transform: scale(0.95);
          }
          &:hover span {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background: $grad2;
              mix-blend-mode: darken;
            }
          }
        }
        &:hover {
          background-color: $white;
          transform: translateY(-20px);
          &:before {
            opacity: 0.05;
          }
          .text {
            color: $dark;
          }
          .btn {
            opacity: 1;
          }
        }
      }
    }
  }
}
