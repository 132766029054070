@import 'style/style.module';
.main {
  background-size: 1440px auto;
  flex: 0 0 auto;
  min-height: 100%;
  .benefits {
    padding-top: 150px;
    padding-bottom: 30px;
    text-align: center;
    @media only screen and (max-width: 1023px) {
      padding-top: 100px;
      padding-bottom: 0;
    }
  }
  .mainContainer {
    @include mainContainer;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 120px;
      font-size: 64px;
      font-weight: 900;
      line-height: 1.1;
      color: $white;
      @media only screen and (max-width: 1023px) {
        margin-bottom: 50px;
        font-size: 36px;
      }
    }
    .number {
      background: url(/images/traders-onboard.svg) no-repeat 0 50%;
      background-size: contain;
      max-width: 1124px;

      width: 100%;
      height: 181px;
      display: block;
      margin-bottom: 80px;
      @media only screen and (max-width: 1023px) {
        margin-bottom: 40px;
        max-height: 107px;
        max-width: 640px;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
        max-height: 59px;
        max-width: 350px;
      }
    }
    .subTitle {
      font-size: 36px;
      line-height: 1.3;
      font-weight: bold;
      color: $white;
      max-width: 19.61em;
      @media only screen and (max-width: 1023px) {
        font-size: 20px;
        line-height: 1.4;
        max-width: 392px;
      }
    }
    .grid {
      background: $blocks url(/images/benefits/bg-hor.svg) no-repeat 50% 50%;
      background-size: cover;
      padding: 0;
      border-radius: 30px;
      margin-top: 80px;
      width: 100%;
      display: flex;
      @media only screen and (max-width: 1023px) {
        margin-top: 50px;
      }
      @media only screen and (max-width: 767px) {
        margin-top: 50px;
        flex-direction: column;
        background: $blocks url(/images/benefits/bg-vert.svg) no-repeat 50% 50%;
        max-width: 350px;
        width: 100%;
      }

      .el {
        padding: 50px 20px;
        flex: 0 0 auto;
        margin: 0 auto;
        width: 33.333333%;
        @media only screen and (max-width: 767px) {
          flex: 0 0 auto;
          width: 100%;
        }
        .pic {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: -22px auto 26px auto;
          max-width: 240px;
          position: relative;
          @media only screen and (max-width: 1023px) {
            margin: -22px auto 5px auto;
          }
          &:before {
            content: '';
            display: block;
            border-radius: 50%;
            background-color: $dark;
          }

          &:after {
            content: '';
            display: block;
            padding-top: 100%;
          }
          .static {
            position: absolute;
            background: url(/images/benefits/lighting.png) no-repeat 0 50%;
            background-size: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: lighten;
          }
          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: lighten;
          }
        }
        &:first-child .pic::before {
          width: 190px;
          height: 190px;
        }

        &:nth-child(2) .pic::before {
          width: 170px;
          height: 170px;
        }

        &:last-child .pic::before {
          width: 190px;
          height: 190px;
        }

        .elTitle {
          font-size: 30px;
          line-height: 1.15;
          margin: 0 0 10px 0;
          font-weight: 900;
          color: $white;
        }
        .elSubtitle {
          font-size: 20px;
          line-height: 1.4;
          margin: 0 auto;
          max-width: 309px;
          font-weight: 600;
          color: $grey;
          @media only screen and (max-width: 1023px) {
            font-size: 18px;
            line-height: 1.45;
          }
        }
      }
    }
  }
}
