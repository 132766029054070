@import 'style/style.module';
@import 'style/landingBreakpoints.scss';

@mixin backgroundGradient($start, $finish) {
  background-image: linear-gradient(to bottom, $dark0 $start, $dark $finish),
    url(/images/top-block/bg-mobile.jpg);
}

.landingWrapper {
  width: 100%;
  margin: 0 auto;
  font: normal 18px/1.4 $font;
  background: $dark;
  position: relative;
  z-index: 10;
  min-width: 380px;

  &:before {
    content: '';
    background-image: url(/images/top-block/bg-big.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 650px;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: background-size 0.2s, background-position 0.2s;
    min-height: 800px;

    @media only screen and (max-height: $maximumBkgFullScreenHeight) {
      background-size: auto 100vh;
    }

    @media only screen and (max-height: 900px) {
      background-size: auto 100%;
      background-position: top left 52%;
    }

    @media only screen and (max-height: 800px) {
      background-size: auto 800px;
    }

    @media only screen and (max-width: $hdWidth) and (max-height: $maximumBkgFullScreenHeight) {
      background-position: top left 52%;
    }

    @media only screen and (max-width: $sdWidth) {
      min-height: 540px;
    }

    @media only screen and (max-width: $mobileWidth) {
      @include backgroundGradient(0%, 10%);
      background-position: 50% 100%, 50% 0;
      background-size: 100% 700px, 100% auto;
    }

    @media only screen and (max-width: $mobileWidth) and (max-height: 1100px) {
      @include backgroundGradient(0%, 20%);
    }

    @media only screen and (max-width: $mobileWidth) and (max-height: 1000px) {
      @include backgroundGradient(20%, 40%);
    }

    @media only screen and (max-width: $mobileWidth) and (max-height: 900px) {
      @include backgroundGradient(20%, 50%);
    }

    @media only screen and (max-width: $mobileWidth) and (max-height: 800px) {
      @include backgroundGradient(50%, 70%);
    }

    @media only screen and (max-width: $mobileWidth) and (max-height: 600px) {
      @include backgroundGradient(60%, 100%);
    }

    @media only screen and (max-height: 390px) {
      background-size: auto 520px;
    }

    @media only screen and (min-width: $hdWidth) and (max-height: 390px) {
      background-size: auto 100%;
    }
  }
}

.contactsWrapper {
  width: 100%;
  margin: 0 auto;
  font: normal 18px/1.4 $font;
  background: $dark;
  position: relative;
  z-index: 10;
}
